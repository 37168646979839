import { IonPopover } from "@ionic/react";
import { useEffect, useState } from "react";
import HeroButton from "../HeroButton";
import { ReactComponent as MaxwellLogo } from '../../icons/logo-45x45.svg';
import memberService, { chatBotState } from "../../Shared/services/MemberService";

import './main.css';
import { useRecoilState } from "recoil";

function MaxChatBot() {
    const [showPopover, setShowPopover] = useState<{open: boolean, event: Event | undefined}>(
      {open: false, event: undefined}
    );

    const [chatBotId, setChatBotId] = useRecoilState(chatBotState);

    useEffect(() => {
      if(chatBotId) return;

      memberService.getChatBotId().then((res) => {
        setChatBotId(res.data);
      });
    }, []);

    const customEmbeddedChatbotHtml = 
      `<script async type=‘module’ src=‘https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js’>
      </script>
      <zapier-interfaces-chatbot-embed is-popup=‘false’ chatbot-id=‘${chatBotId}’ height=‘600px’ width=‘300px’>
      </zapier-interfaces-chatbot-embed>`;

    const iFrameSource = `https://interfaces.zapier.com/embed/chatbot/${chatBotId}`;

    if(chatBotId){
      return (
        <>
          <IonPopover
            isOpen={showPopover.open}
            event={showPopover.event}
            onDidDismiss={(e) => setShowPopover({open: false, event: undefined})}
          >
            <div dangerouslySetInnerHTML={{__html: customEmbeddedChatbotHtml}}>
              {/* Placeholder for the chatbot */}
            </div>
            <iframe
              src={iFrameSource}
              height="400px"
              width="300px"
              allow="clipboard-write *"
            >
            </iframe>
          </IonPopover>
          <HeroButton 
            title='Ask Max'
            textPadding={0}
            clickHandler={(e: { nativeEvent: Event; }) => setShowPopover({open: true, event: e.nativeEvent})}>
              <div
               style={{
                marginRight: 'auto',
                marginLeft: 'auto'
               }}>
                <MaxwellLogo/>
              </div>
          </HeroButton>
        </>
      );
    } else {
      return null;
    }
};

export default MaxChatBot;